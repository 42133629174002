.navbar {
    background-color: var(--card-background);
    padding: var(--spacing-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav-brand a {
    color: var(--text-primary);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: var(--spacing-lg);
    margin: 0;
    padding: 0;
}

.nav-links a {
    color: var(--text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
}

.nav-links a:hover {
    color: var(--primary-color);
}

@media (max-width: 768px) {
    .nav-links {
        gap: var(--spacing-md);
    }
} 