.place-container {
    padding: var(--spacing-lg);
    background-color: var(--background);
    min-height: 100vh;
}

.place-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-lg);
    padding: var(--spacing-lg);
    background: var(--card-background);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
}

.place-image img {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
}

.place-description {
    padding: var(--spacing-md);
}

.place-description h2,
.place-description h3 {
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
}

.place-description p,
.place-description li {
    color: var(--text-secondary);
    line-height: 1.6;
}

.place-description ul {
    list-style-type: none;
    padding: 0;
}

.place-description li {
    margin-bottom: var(--spacing-sm);
}

@media (max-width: 768px) {
    .place-container {
        padding: var(--spacing-md);
    }
    
    .place-content {
        grid-template-columns: 1fr;
        padding: var(--spacing-md);
    }
} 