.about-page {
    padding: 20px;
    text-align: center;
}

.about-page h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.about-page p {
    font-size: 1.2em;
    line-height: 1.5;
} 