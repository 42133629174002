.home-container {
    padding: var(--spacing-lg);
    background-color: var(--background);
    min-height: 100vh;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-lg);
}

.cta-button {
    display: inline-block;
    padding: var(--spacing-md) var(--spacing-lg);
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    border-radius: var(--border-radius);
    margin-top: var(--spacing-lg);
    transition: background-color 0.2s ease;
    border: none;
    cursor: pointer;
}

.cta-button:hover {
    background-color: var(--primary-hover);
}

.places-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: var(--spacing-lg);
    margin: var(--spacing-lg) 0;
}

.place-card {
    background: var(--card-background);
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease;
}

.place-card:hover {
    transform: translateY(-4px);
}

.place-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.place-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.place-info {
    padding: var(--spacing-md);
}

.place-info h3 {
    margin: 0 0 var(--spacing-sm) 0;
    color: var(--text-primary);
}

.place-info p {
    margin: 0;
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .home-container {
        padding: var(--spacing-md);
    }
    
    .content {
        padding: var(--spacing-md);
    }
} 