.places-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.content {
    margin-top: 2rem;
}

.places-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.place-card {
    text-decoration: none;
    color: inherit;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.place-card:hover {
    transform: translateY(-5px);
}

.place-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.place-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.place-info {
    padding: 1rem;
}

.place-info h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
}

.place-info p {
    margin: 0;
    color: #666;
} 