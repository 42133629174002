:root {
  --primary-color: #2196f3;
  --primary-hover: #1976d2;
  --text-primary: #333;
  --text-secondary: #666;
  --background: #f5f5f5;
  --card-background: #ffffff;
  --shadow: 0 2px 4px rgba(0,0,0,0.1);
  --border-radius: 8px;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
} 